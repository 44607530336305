import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const SeasonOfferBanner = () => {
  const data = useStaticQuery(graphql`
    {
      seasonofferbanner: allContentfulSeasonOfferBanner {
        edges {
          node {
            id
            seasonName
            title
            image {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const { seasonName, title } = data.seasonofferbanner.edges[0].node
  const img = data.seasonofferbanner.edges[0].node.image.fluid

  return (
    <section className="bg-amber-100">
      <div className="grid grid-cols-6 mx-5 md:mx-20">
        <div className="lg:col-span-4 md:col-span-4 col-span-4 px-5 md:px-0 md:pr-10 md:py-16 py-12">
          <h1 className="text-sm md:text-lg lg:text-xl xl:text-2xl pb-1 md:pb-3 lg:pb-4">
            {seasonName}
          </h1>
          <h2 className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl leading-tight font-bold pb-6 md:pb-8 lg:pb-10 xl:pb-14 text-black">
            {title}
          </h2>
          <Link to="/store" className="" target="_blank">
            <button
              className=" text-white bg-black py-2 px-6 font-metropolis font-normal text-xs lg:text-sm md:px-8 md:py-3 lg:py-3 lg:px-10 xl:py-4 xl:px-14 xl:text-base rounded-md hover:bg-white border hover:border-gray-400 hover:text-black focus:outline-none transition-all duration-300 ease-linear tracking-wide"
              aria-label="Explore"
            >
              SHOP NOW
            </button>
          </Link>
        </div>
        <div className="lg:col-span-2 md:col-span-2 col-span-2 md:py-16 py-12">
          <Img fluid={img} alt="" className="lg:w-4/5 w-full h-full" />
        </div>
      </div>
    </section>
  )
}

export default SeasonOfferBanner
