import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeroSection from "../components/HeroSection"
import Services from "../components/Services"
import NewLaunch from "../components/NewLaunch"
import DiscountCodeBanner from "../components/DiscountCodeBanner"
import SeasonOfferBanner from "../components/SeasonOfferBanner"
import BestSeller from "../components/BestSeller"
import Newsletter from "../components/Newsletter"
//import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <HeroSection content={data.herosection.edges[0].node} />
    <Services />
    <NewLaunch />
    <DiscountCodeBanner />
    <BestSeller />
    <SeasonOfferBanner />
    <Newsletter />
  </Layout>
)

export const query = graphql`
  {
    herosection: allContentfulHeroSection {
      edges {
        node {
          heroText
          heroSubText {
            heroSubText
          }
          heroImage {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default IndexPage
