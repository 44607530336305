import React from "react"
import FeaturedProduct from "./FeaturedProduct"
//import Layout from "./layout"
import { Link } from "gatsby"
import Img from "gatsby-image"

const HeroSection = ({ content }) => {
  return (
    <div className="pt-14 md:pt-12 lg:pt-24 xl:pt-28">
      <div className="h-auto mb-5 lg:mb-12 xl:mb-14">
        <div className="max-w-screen-lg xl:max-w-screen-xl lg:mx-12 xl:mx-auto">
          {/*XL above 1024px */}
          <div className="hidden lg:grid grid-cols-1 lg:grid-cols-2 gap-5 xl:gap-10 bg-dark">
            <div className="flex flex-col justify-center">
              <div className="lg:text-4xl xl:text-6xl text-xl font-bold pb-6 leading-none">
                <span className="bg-clip-text text-transparent bg-gradient-to-br from-amber-800 via-yellow-400 to-amber-700">
                  {content.heroText}
                </span>
              </div>

              <p className="text-amber-900 font-normal lg:text-2xl text-lg tracking-tight leading-tight pb-12">
                {content.heroSubText.heroSubText}
              </p>

              <div className="flex space-x-10 md:space-x-8">
                <Link to="/store" target="_blank">
                  <button
                    className=" text-white bg-amber-900 py-3 px-10 font-metropolis font-normal text-sm lg:py-3 lg:px-12 rounded-lg hover:bg-white border hover:border-black hover:text-black focus:outline-none transition-all duration-300 ease-linear tracking-wide"
                    aria-label="Explore"
                  >
                    EXPLORE
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex flex-col items-end justify-center pr-10 pt-5">
              <Img
                fluid={content.heroImage.fluid}
                alt=""
                className="xl:w-9/12 lg:w-4/5"
              />
            </div>
          </div>
          {/*Mobile and Tablet upto 1024px */}
          <div className="lg:hidden grid grid-cols-1 gap-y-5 md:gap-y-8 xl:grid-cols-2 pt-10 bg-dark sm:pt-20 md:pt-12 ">
            <div className="flex flex-col items-center justify-center">
              <Img
                fluid={content.heroImage.fluid}
                alt=""
                className="w-4/6 md:w-3/6"
              />
            </div>
            <div className="flex flex-col justify-center pt-4 sm:pt-0">
              <div className="font-metropolis font-semibold lg:text-4xl text-2xl md:text-3xl  pb-4 leading-none tracking-tight px-6 sm:px-16 md:px-14 lg:px-0">
                <span className="bg-clip-text text-transparent bg-gradient-to-br from-amber-800 via-yellow-400 to-amber-700">
                  {content.heroText}
                </span>
              </div>

              <p className=" text-amber-900 font-normal lg:text-2xl text-base sm:text-lg tracking-tight leading-tight pb-8 sm:pb-10 px-6 sm:px-16 md:px-14 lg:px-0">
                {content.heroSubText.heroSubText}
              </p>

              <div className="sm:flex px-6 sm:px-16 md:px-14 mb-10 md:mb-16 lg:mb-10">
                <Link to="/store">
                  <button
                    className="text-white bg-amber-900 py-3 px-10 font-metropolis font-normal text-sm lg:py-3 lg:px-12 rounded-lg hover:bg-white border hover:border-black hover:text-black focus:outline-none transition-all duration-300 ease-linear tracking-wide mb-5 sm:mb-0"
                    aria-label="Explore"
                  >
                    EXPLORE
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeaturedProduct />
    </div>
  )
}

export default HeroSection
