import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import BestSellerProduct from "./BestSellerProduct"

const BestSeller = () => {
  const data = useStaticQuery(graphql`
    {
      bestsellerproducts: allContentfulAllProducts(
        filter: { bestSeller: { eq: true } }
      ) {
        edges {
          node {
            id
            name
            productSlug
            price
            image {
              fluid(maxHeight: 426) {
                src
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)
  return (
    <section className="my-28 md:my-32 lg:my-36 xl:my-48">
      <div className="max-w-screen-lg xl:max-w-screen-xl lg:mx-5 xl:mx-auto">
        <div className="flex justify-between items-center pb-10 md:pb-10 lg:pb-12 xl:pb-16 xl:mx-0">
          <h1 className="font-semibold md:font-bold text-xl md:text-2xl xl:text-3xl max-w-screen-lg px-6 sm:px-16 md:px-14 lg:px-8 xl:px-0">
            Best Seller
          </h1>
          <Link
            to="/store"
            className="px-6 sm:px-16 md:px-14 lg:px-8 xl:px-0"
            target="_blank"
          >
            <button
              className=" text-black bg-white py-2 px-6 font-metropolis font-normal text-xs lg:text-sm md:px-8 lg:py-3 lg:px-10 rounded-md hover:bg-black border border-gray-400 hover:text-white focus:outline-none transition-all duration-300 ease-linear tracking-wide"
              aria-label="Explore"
            >
              SEE ALL PRODUCTS
            </button>
          </Link>
        </div>
        <div className="w-auto lg:w-full px-6 md:px-14 lg:px-8 xl:px-0 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 gap-y-5 md:gap-5 md:gap-y-7 lg:gap-6 lg:gap-y-8 xl:gap-12">
          {data.bestsellerproducts.edges.map(({ node: product }) => {
            return <BestSellerProduct key={product.id} product={product} />
          })}
        </div>
      </div>
    </section>
  )
}

export default BestSeller
