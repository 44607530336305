import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const DiscountCodeBanner = () => {
  const data = useStaticQuery(graphql`
    {
      discountcodebanner: allContentfulDiscountCodeBanner {
        edges {
          node {
            id
            title
            code
            image {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const { title, code } = data.discountcodebanner.edges[0].node
  const img = data.discountcodebanner.edges[0].node.image.fluid

  return (
    <section className="bg-amber-100">
      <div className="grid md:grid-cols-6 mx-10 md:mx-20">
        <div className="lg:col-span-4 md:col-span-4 col-span-6 md:px-0 md:pr-10 lg:px-20 md:py-16 py-12">
          <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl leading-tight font-bold pb-1 md:pb-3 lg:pb-4 text-black">
            {title}
          </h1>
          <h2 className="text-base md:text-lg lg:text-xl xl:text-2xl pb-4 md:pb-6 lg:pb-8 xl:pb-10">
            Use Code: {code}
          </h2>
          <Link to="/store" className="sm:px-0" target="_blank">
            <button
              className=" text-white bg-black py-3 px-8 font-metropolis font-normal text-xs lg:text-sm md:px-8 md:py-3 lg:py-3 lg:px-10 xl:py-4 xl:px-14 xl:text-base rounded-md hover:bg-white border hover:border-gray-400 hover:text-black focus:outline-none transition-all duration-300 ease-linear tracking-wide"
              aria-label="Explore"
            >
              SHOP NOW
            </button>
          </Link>
          <p className="text-sm md:text-base pt-2 italic">
            *Use this code on checkout to avail discount.
          </p>
        </div>
        <div className="hidden md:grid lg:col-span-2 md:col-span-2 lg:py-16 md:py-20">
          <Img fluid={img} alt="" className="lg:w-4/5 md:w-full" />
        </div>
      </div>
    </section>
  )
}

export default DiscountCodeBanner
