import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SingleFeaturedProduct from "./SingleFeaturedProduct"
import { Link } from "gatsby"

const FeaturedProduct = () => {
  const data = useStaticQuery(graphql`
    {
      featuredproducts: allContentfulAllProducts(
        filter: { featured: { eq: true } }
      ) {
        edges {
          node {
            id
            productSlug
            name
            price
            image {
              fluid(maxHeight: 426, quality: 100) {
                src
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className="relative max-w-screen-lg xl:max-w-screen-xl lg:mx-5 xl:mx-10 xl:flex items-center">
      <h1 className="hidden text-amber-800 xl:inline-block font-bold text-2xl absolute transform -rotate-90">
        Featured
      </h1>
      <h1 className="xl:hidden text-amber-800 font-semibold md:font-bold text-2xl max-w-screen-lg px-6 sm:px-16 md:px-14 lg:px-8 xl:px-0 pb-6 md:pb-8 lg:pb-8">
        Featured
      </h1>
      <div className="w-auto lg:w-full px-6 md:px-14 lg:px-8 xl:px-0 grid grid-cols-2 lg:grid-cols-4 gap-3 md:gap-14 lg:gap-8 xl:gap-16 xl:ml-32">
        {data.featuredproducts.edges.map(({ node: product }) => {
          return <SingleFeaturedProduct key={product.id} product={product} />
        })}
        <div className="flex justify-center items-center">
          <Link
            to="/store"
            className="flex justify-center items-center w-12 h-12 rounded-full shadow-lg bg-gradient-to-r from-amber-800 to-amber-400 text-white cursor-pointer"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default FeaturedProduct
