import React from "react"

const Services = () => {
  return (
    <section className="w-full h-auto my-28 md:my-32 lg:my-32 xl:my-40">
      <div className="max-w-screen-lg xl:mx-auto mx-5 md:mx-0">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-16 md:gap-x-5 lg:gap-x-10 xl:gap-x-20 mx-10 md:mx-16 xl:mx-0">
          <div className="text-center font-lato flex flex-col items-center">
            <div className="text-amber-600">
              <svg
                class="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
            <h1 className="pt-6 pb-2 font-semibold text-base lg:text-lg text-amber-800">
              Money Back Guarantee
            </h1>
            <p className="text-sm md:text-xs lg:text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>
          <div className="text-center font-lato flex flex-col items-center">
            <div className="text-amber-600">
              <svg
                class="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                ></path>
              </svg>
            </div>
            <h1 className="pt-6 pb-2 font-semibold text-base lg:text-lg text-amber-800">
              Secure Payment
            </h1>
            <p className=" text-sm md:text-xs lg:text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>
          <div className="text-center font-lato flex flex-col items-center">
            <div className="text-amber-600">
              <svg
                class="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
            <h1 className="pt-6 pb-2 font-semibold text-base lg:text-lg text-amber-800">
              24/7 Customer Service
            </h1>
            <p className="text-sm md:text-xs lg:text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat
              nibh tristique ipsum.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
