import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const NewLaunchProduct = ({ product }) => {
  return (
    <Link key={product.productSlug} to={`/products/${product.productSlug}`}>
      <div className="bg-amber-100 rounded-lg overflow-hidden border border-amber-100">
        <div className="">
          <Img
            className="w-full h-56 object-contain"
            fluid={product.image.fluid}
            alt="card-image"
          />
        </div>
        <div className="pl-4 md:pl-6">
          <h1 className="md:text-lg xl:text-lg pt-5 pb-2 font-light">
            {product.name}
          </h1>

          <p className="text-lg lg:text-xl tracking-wide font-semibold text-amber-900  pb-3 md:pb-4 rounded-md">
            ${product.price}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default NewLaunchProduct;
